"use client";

import {
  Features,
  Plan,
  PlanType,
  Plans,
  Tiers,
  appsumoPlans,
  comparePlans,
  getCheckoutUrl,
  upcomingFeatures,
} from "./definitions";
import { Y, X, Button, Text, Image } from "@reframe.so/blocks";

import { ArrowRightIcon } from "@reframe.so/icons/icons/ArrowRightIcon";
import { CheckIcon } from "@reframe.so/icons/icons/CheckIcon";
import { ChevronDownIcon } from "@reframe.so/icons/icons/ChevronDownIcon";
import { ChevronUpIcon } from "@reframe.so/icons/icons/ChevronUpIcon";
import { PlusIcon } from "@reframe.so/icons/icons/PlusIcon";
import { LinkButton } from "@src/components/link-button";

import { useCurrentPlan } from "@src/hooks/current-plan";
import { EVENT_CONST } from "@src/mixpanel/constant";
import { useMixpanel } from "@src/mixpanel/context";
import { useSession } from "next-auth/react";
import { usePathname, useSearchParams } from "next/navigation";
import { useEffect, useState } from "react";

export const PlanDetails = ({
  planType,
  duration,
  highlight: _highlight,
}: {
  planType: PlanType;
  duration: keyof Plan["price"];
  highlight?: boolean;
}) => {
  const session = useSession();

  const currentPlan = useCurrentPlan();
  const { track } = useMixpanel();

  useEffect(() => {
    track(EVENT_CONST.SUBCRIBE_VIEWED);
  }, [track]);

  const plan = Plans[planType];
  const highlight =
    _highlight ??
    (Plans[currentPlan.plan].next ?? currentPlan.plan) === planType;

  const [extend, setExtend] = useState(
    _highlight ?? currentPlan.plan === planType
  );

  const features = Object.entries(Features).flatMap(([key, feature]) => {
    const value = Tiers[plan.tier][key as keyof Features];
    const previousValue = plan.previous
      ? Tiers[Plans[plan.previous].tier][key as keyof Features]
      : undefined;

    if (!extend && value === previousValue) {
      return [];
    }

    const upcoming = upcomingFeatures.includes(key as any) ? (
      <> (coming soon!)</>
    ) : null;

    switch (feature.type) {
      case "boolean":
        return value ? (
          <>
            {feature.name}
            {upcoming}
          </>
        ) : (
          []
        );
      case "number":
        return value ? (
          <>
            <Text css="text-primary-600">
              {value === Infinity ? "Unlimited" : value}
            </Text>{" "}
            {feature.name}
            {upcoming}
          </>
        ) : (
          []
        );
      case "enum":
        return value ? (
          <>
            <Text css="text-primary-600">{value}</Text> {feature.name}
            {upcoming}
          </>
        ) : (
          []
        );

      default:
        return [];
    }
  });

  const price = (price: number) => {
    if (price === 0) {
      return <>Free</>;
    }

    switch (duration) {
      case "monthly":
        return (
          <>
            ${price}{" "}
            <Text css="text-xs font-semibold text-neutral-600">/month</Text>
          </>
        );
      case "yearly":
        return (
          <>
            ${price}{" "}
            <Text css="text-xs font-semibold text-neutral-600">/year</Text>
          </>
        );
      case "lifetime":
        return (
          <>
            ${price}{" "}
            <Text css="text-xs font-semibold text-neutral-600">/forever</Text>
          </>
        );
    }
  };

  const comparison = comparePlans(currentPlan.plan, planType);
  const pathname = usePathname()?.substring(1) ?? "";
  const search = useSearchParams()?.toString() ?? "";
  const currentUrl = pathname + (search ? "?" + search : "");

  return (
    <Y
      css={[
        "items-start justify-center hug basis-1/3 min-w-[80vw] sm:min-w-[340px] p-4 grow",
        highlight ? "basis-2/3" : "",
      ]}
      onClick={() => track(EVENT_CONST.PLAN_CLICKED, { planType, duration })}
    >
      <Y
        css={[
          "bg-gradient-to-t grow hug rounded-3xl p-8 ring-1 ring-gray-900/10 ",
          highlight ? " bg-white ring-2 ring-indigo-600" : "bg-white/60",
        ]}
      >
        <Y css="hug grow rounded-xl justify-between gap-6 xl:gap-8">
          <Y css="gap-2">
            <X css="items-center gap-2">
              <Text css="text-md font-normal text-primary-500 ">
                {plan.name}
              </Text>
            </X>
            <Text css=" text-3xl font-semibold">
              {price(plan.price[duration])}
            </Text>
            {/* <Text css=" text-sm font-light text-neutral-400">{plan.details}</Text> */}
          </Y>
          <Y css="gap-1 wrap">
            {plan.previous ? (
              <Button
                css="gap-2 grow items-center w-full p-0 justify-start h-auto min-h-[24px]"
                variant="link"
                onClick={() => setExtend(!extend)}
              >
                <PlusIcon css="w-4 h-4 text-primary-600/80" width={2} />
                <Text css="text-sm text-primary-400 font-semibold tracking-tight first-letter:capitalize">
                  Everything in {Plans[plan.previous].name}
                </Text>
                {!extend ? (
                  <ChevronDownIcon
                    css="w-4 h-4 text-primary-600/80 -ml-1"
                    width={2}
                  />
                ) : (
                  <ChevronUpIcon
                    css="w-4 h-4 text-primary-600/80 -ml-1"
                    width={2}
                  />
                )}
              </Button>
            ) : null}
            {features.map((features, index) => (
              <X key={index} css="h-auto min-h-[24px] gap-2 grow items-center">
                <CheckIcon css="w-4 h-4 text-primary-600/80" width={1.5} />
                <Text
                  key={index}
                  css="text-sm text-neutral-600 font-normal tracking-tight first-letter:capitalize"
                >
                  {features}
                </Text>
              </X>
            ))}
          </Y>

          <Y>
            {planType === currentPlan.plan &&
            duration === currentPlan.duration ? (
              <Button
                variant="ghost"
                css={[
                  "justify-between h-10 ring-1 text-xs gap-2",
                  "shadow-md cursor-auto pointer-events-none",
                ]}
              >
                {session.status !== "authenticated" ? (
                  <>
                    <Text>Sign up for free</Text>
                    <ArrowRightIcon css="w-3 h-3 stroke-2" />
                  </>
                ) : (
                  <Text css="">Current Plan</Text>
                )}
              </Button>
            ) : (
              <Y css="gap-2">
                <LinkButton
                  variant={!highlight ? "ghost" : "default"}
                  confirm={comparison > 0}
                  css={["justify-between h-10 ring-1 text-xs gap-2 w-full"]}
                  href={
                    session.status === "authenticated"
                      ? getCheckoutUrl(currentUrl, planType, duration)
                      : "/sign-in?next=subscriptions"
                  }
                  external
                >
                  <Text css="text-sm">
                    {session.status !== "authenticated"
                      ? "Sign up for free"
                      : comparison === 0
                      ? `Switch to ${duration}`
                      : comparison > 0
                      ? "Downgrade"
                      : duration === "lifetime"
                      ? "Buy now"
                      : "Upgrade"}
                  </Text>
                  <ArrowRightIcon css="w-3 h-3 stroke-2" />
                </LinkButton>
                {duration === "yearly" && !appsumoPlans.includes(planType) ? (
                  <Text css="text-sm text-neutral-600 self-center text-center">
                    <Text css="font-medium">30 day money-back guarantee</Text>
                    {highlight ? (
                      <>
                        . Try it out for a month to make sure it's right for
                        you!
                      </>
                    ) : null}
                  </Text>
                ) : null}
              </Y>
            )}
            {appsumoPlans.includes(planType) ? (
              <Image
                src="/assets/images/appsumo.svg"
                alt="appsumo"
                css="w-full h-3 mt-3"
              />
            ) : null}
          </Y>
        </Y>
      </Y>
    </Y>
  );
};
