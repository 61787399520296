export function mapObject<K extends string | number | symbol, T, U>(
  obj: {
    [_key in K]: T;
  },
  fn: (_value: T, _key: string) => U
): {
  [_key in K]: U;
} {
  return Object.fromEntries(
    Object.entries<T>(obj).map(([key, value]) => [key, fn(value, key)] as const)
  ) as {
    [_key in K]: U;
  };
}

export function filterObject<K extends string | number | symbol, T>(
  obj: {
    [_key in K]: T;
  },
  fn: (_value: T, _key: string) => boolean
): {
  [_key in K]: T;
} {
  return Object.fromEntries(
    Object.entries<T>(obj).filter(([key, value]) => fn(value, key))
  ) as {
    [_key in K]: T;
  };
}
