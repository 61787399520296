import { mapObject } from "@src/utils/array";

export const Features = {
  "published-chatbots": {
    name: "published chatbots",
    type: "number",
  },
  "personal-chatbots": {
    name: "personal chatbots",
    type: "number",
  },
  workspaces: {
    name: "workspaces",
    type: "number",
  },
  "messages-per-chatbot": {
    name: "messages per chatbot",
    type: "number",
  },
  "users-per-chatbot": {
    name: "users per chatbot",
    type: "number",
  },
  "crawl-websites": {
    name: "train with website crawling",
    type: "boolean",
  },
  "upload-data": {
    name: "train on your own data",
    type: "boolean",
  },
  "file-uploads": {
    name: "file uploads / month",
    type: "number",
  },
  "infinite-memory": {
    name: "infinite memory in chatbots",
    type: "boolean",
  },
  "customize-chat-widget": {
    name: "customize your chat widget",
    type: "boolean",
  },
  "early-access": {
    name: "early access to new features",
    type: "boolean",
  },
  "remove-branding": {
    name: "remove branding",
    type: "boolean",
  },
  "custom-domain": {
    name: "custom domain and whitelabeling",
    type: "boolean",
  },
  "usage-history": {
    name: "usage history",
    type: "enum",
    options: ["7 days", "Unlimited"],
  },

  // upcoming

  "sync-websites": {
    name: "sync with website crawling",
    type: "boolean",
    upcoming: true,
  },
} as const satisfies Record<
  string,
  {
    name: string;
    upcoming?: true;
  } & (
    | {
        type: "number";
      }
    | {
        type: "boolean";
      }
    | {
        type: "enum";
        options: readonly string[];
      }
  )
>;

export type Features = typeof Features;
export type FeatureType = keyof Features;
export type Feature = Features[FeatureType];

export type FeatureValue<T extends Feature> = T["type"] extends "number"
  ? number
  : T["type"] extends "boolean"
  ? boolean
  : T extends { type: "enum" }
  ? T["options"][number]
  : never;

type IsUpcoming<T extends Feature> = "upcoming" extends keyof T ? true : false;

export const upcomingFeatures = Object.keys(Features).filter(
  (key) => "upcoming" in Features[key as FeatureType]
) as (keyof {
  [key in keyof typeof Features as IsUpcoming<
    (typeof Features)[key]
  > extends true
    ? key
    : never]: true;
})[];

export type Tier = {
  [key in FeatureType]: FeatureValue<Features[key]>;
};

const free = {
  "personal-chatbots": 10,
  "messages-per-chatbot": 1000,
  "users-per-chatbot": 100,
  "usage-history": "7 days",
  "crawl-websites": false,
  "infinite-memory": false,
  "customize-chat-widget": false,
  "early-access": false,
  "published-chatbots": 1,
  "upload-data": true,
  "file-uploads": 5,
  "remove-branding": false,
  "custom-domain": false,
  workspaces: 1,

  "sync-websites": false,
} as const satisfies Tier;

const launch = {
  ...free,
  "personal-chatbots": Infinity,
  "messages-per-chatbot": Infinity,
  "users-per-chatbot": Infinity,
  "usage-history": "Unlimited",
  "crawl-websites": true,
  "infinite-memory": true,
  "customize-chat-widget": true,
  "early-access": true,
  "published-chatbots": 1,
  "file-uploads": 50,
} as const satisfies Tier;

const grow = {
  ...launch,
  "published-chatbots": 5,
  "file-uploads": 500,
  workspaces: 5,
} as const satisfies Tier;

const scale = {
  ...grow,
  "published-chatbots": 10,
  "file-uploads": Infinity,
  "remove-branding": true,
  workspaces: 10,

  "sync-websites": true,
} as const satisfies Tier;

const max = {
  ...scale,
  "published-chatbots": Infinity,
  "custom-domain": true,
  workspaces: Infinity,
} as const satisfies Tier;

export const Tiers = {
  free,
  launch,
  grow,
  scale,
  max,
} as const satisfies Record<string, Tier>;

export const Plans = {
  free: {
    previous: null,
    next: "launch",
    tier: "free",
    name: "Hobby",
    price: {
      monthly: 0,
      yearly: 0,
      lifetime: 0,
    },
  },
  launch: {
    previous: "free",
    next: "grow",
    tier: "launch",
    name: "Launch",
    price: {
      monthly: 49,
      yearly: 499,
      lifetime: 299,
    },
  },
  retune_tier1: {
    previous: "free",
    next: "retune_tier2",
    tier: "launch",
    name: "AppSumo Tier 1",
    price: {
      monthly: 29,
      yearly: 49,
      lifetime: 59,
    },
  },
  grow: {
    previous: "launch",
    next: "scale",
    tier: "grow",
    name: "Grow",
    price: {
      monthly: 99,
      yearly: 999,
      lifetime: 499,
    },
  },
  retune_tier2: {
    previous: "retune_tier1",
    next: "retune_tier3",
    tier: "grow",
    name: "AppSumo Tier 2",
    price: {
      monthly: 49,
      yearly: 129,
      lifetime: 129,
    },
  },
  scale: {
    previous: "grow",
    next: "max",
    tier: "scale",
    name: "Scale",
    price: {
      monthly: 199,
      yearly: 1999,
      lifetime: 999,
    },
  },
  retune_tier3: {
    previous: "retune_tier2",
    next: "retune_tier4",
    tier: "scale",
    name: "AppSumo Tier 3",
    price: {
      monthly: 99,
      yearly: 279,
      lifetime: 279,
    },
  },
  max: {
    previous: "scale",
    next: null,
    tier: "max",
    name: "Max",
    price: {
      monthly: 399,
      yearly: 3999,
      lifetime: 1999,
    },
  },
  retune_tier4: {
    previous: "retune_tier3",
    next: null,
    tier: "max",
    name: "AppSumo Tier 4",
    price: {
      monthly: 199,
      yearly: 399,
      lifetime: 399,
    },
  },
} as const satisfies Record<
  string,
  {
    previous: string | null;
    next: string | null;
    tier: keyof typeof Tiers;
    name: string;
    details?: string;
    price: {
      monthly: number;
      yearly: number;
      lifetime: number;
    };
  }
>;

export type Plans = typeof Plans;
export type PlanType = keyof Plans;
export type Plan = Plans[keyof Plans];

export type Duration = keyof Plan["price"];

export const appsumoPlans = [
  "retune_tier1",
  "retune_tier2",
  "retune_tier3",
  "retune_tier4",
];

type PreviousPlan<T extends PlanType> = T extends keyof Plans
  ? Plans[T]["previous"] extends PlanType
    ? Plans[T]["previous"]
    : null
  : null;

const previousPlan = mapObject(Plans, (plan) => plan.previous) as {
  [key in PlanType]: PreviousPlan<key>;
};

type NextPlan<T extends PlanType> = T extends keyof Plans
  ? Plans[T]["next"] extends PlanType
    ? Plans[T]["next"]
    : null
  : null;

const nextPlan = mapObject(Plans, (plan) => plan.next) as {
  [key in PlanType]: NextPlan<key>;
};

export const getFeatureValue = <F extends FeatureType>(
  plan: PlanType,
  feature: F
) => Tiers[Plans[plan].tier][feature] as unknown as FeatureValue<Features[F]>;

export const isFeatureEnabled = <F extends FeatureType>(
  plan: PlanType,
  feature: F,
  usage: number = 0
) => {
  const value = getFeatureValue(plan, feature);

  if (typeof value === "boolean") {
    return value;
  }

  if (typeof value === "number") {
    return value > usage;
  }

  throw new Error(`Unsupported feature value type: ${value} for ${feature}`);
};

export const featureEnablesIn = <F extends FeatureType>(
  plan: PlanType,
  feature: F,
  usage: number = 0
): PlanType | undefined => {
  if (isFeatureEnabled(plan, feature, usage)) {
    return plan;
  }

  const next = nextPlan[plan];

  if (next) {
    return featureEnablesIn(next, feature, usage);
  }
};

export const comparePlans = (a: PlanType, b: PlanType) => {
  return Plans[a].price.monthly - Plans[b].price.monthly;
};

export const getCheckoutUrl = (
  currentUrl: string,
  plan: PlanType,
  duration: Duration
) => {
  if (appsumoPlans.includes(plan)) {
    return "http://appsumo.8odi.net/PyVKkR";
  }

  return `/payment/checkout?plan=${plan}&duration=${duration}&next=${encodeURIComponent(
    currentUrl
  )}`;
};
