import { PlanType, Duration } from "@src/app/(app)/subscriptions/definitions";
import { useSession } from "next-auth/react";

export const useCurrentPlan = () => {
  const [plan = "free", duration = "yearly"] =
    useSession().data?.user?.plan?.split(":") ?? [];

  return { plan, duration } as {
    plan: PlanType;
    duration: Duration;
  };
};
